<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Request Test From</span>
      </h3>
    </div>
    <div>
      <div>
        <v-row>
          <v-col class="d-flex" cols="12" sm="3">
            <v-text-field
              v-model="search"
              label="Search "
              counter
              filled
              rounded
              clearable
              dense
              maxlength="50"
            >
              <template #label> <i class="fas fa-search"></i> Search </template>
            </v-text-field>
          </v-col>
          <v-col class="d-flex mt-4" cols="12" sm="6">
            <v-btn> Search </v-btn></v-col
          ></v-row
        >
      </div>
    </div>
    <div class="container mt-10" style="background-color: #ffffff">
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
        select-mode="single"
        selectable
      >
        <template #cell(status)="row">
          <span :class="statusClass(row.item.status)">
            {{ row.item.status }}
          </span>
        </template>
        <template #cell(options)="row">
          <b-dropdown right variant="link" no-caret>
            <template #button-content>
              <i class="mdi mdi-dots-vertical icon-medium"></i>
            </template>
            <b-dropdown-item @click="setStatus(row.item, 'Accept')">Accept</b-dropdown-item>
            <b-dropdown-item @click="setStatus(row.item, 'Deny')">Deny</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
 
  </div>
</template>
  <script>
export default {
  data() {
    return {
      isEditAble: true,
      isfetching: false,
      isRemoving: false,
      isSending: false,
      roleid: "",
      items: [
        {
          name: "John Doe",
          clientname: "Client A",
          starttime: "10:00 AM",
          endtime: "12:00 PM",
          date: "2023-05-20",
          service: "Consultation",
          status: "Pending",
        },
        {
          name: "Jane Smith",
          clientname: "Client B",
          starttime: "1:00 PM",
          endtime: "3:00 PM",
          date: "2023-05-21",
          service: "Therapy",
          status: "Pending",
        },
        {
          name: "Mike Johnson",
          clientname: "Client C",
          starttime: "2:00 PM",
          endtime: "4:00 PM",
          date: "2023-05-22",
          service: "Checkup",
          status: "Approved",
        },
      ],
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "clientname",
          label: "Client Name",
        },
        {
          key: "starttime",
          label: "Start Time",
        },
        {
          key: "endtime",
          label: "End Time",
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "service",
          label: "Service",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "options",
          label: "Options",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "", 
    };
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  methods: {
    statusClass(status) {
      if (status === "Approved") {
        return "text-green-500";
      } else if (status === "Pending") {
        return "text-yellow-500";
      } else {
        return "";
      }
    },
    Search() {
      this.fetchData();
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getEVVForm", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          CareGiverId: this.CareGiverId,
          ClientId: this.ClientId,
          SubCodeId: this.SubCodeId,
          Date: this.Date,

        })
        .then((response) => {
          if (response.message == "Success") {
            this.items = response.data.output;
            this.totalItems = response.data.totalRow;
          }

          this.isfetching = false;
        })
        .catch((ex) => {
          this.isfetching = false;
          
        });
    },
    viewDetails(item) {
      console.log("Viewing details for", item);
    },
    deleteItem(item) {
      console.log("Deleting item", item);
    },
  },
};
</script>
  <style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
.text-green-500 {
  color: #10b981 !important;
}
.text-yellow-500 {
  color: #f59e0b !important;
}
.icon-medium {
  font-size: 24px; /* Adjust the size as needed */
}
</style>
  